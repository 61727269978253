import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './Message.css';

export default function Message({ date, content, user, error }) {
  return (
    <Row className={ user ? "justify-content-end" : "" }>
      <Col md="auto">
        <Card className="border-0 message">
          <Card.Body className={ error ? "error-card" : user ? "user-card" : "ai-card" }>
            <Card.Subtitle className="mb-2" style={{ color: "#777" }}>{ date.toLocaleString().replace(',', '') }</Card.Subtitle>
            <Card.Text>
              { content }
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
