import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { useParams } from "react-router-dom";
import Message from './Message';
import InputBox from './InputBox';

export default function Chat() {
  const { uuid } = useParams();
  const url = process.env.REACT_APP_BACKEND_URL;
  const [messages, setMessages] = useState([]);
  const [waitingForReply, setWaitingForReply] = useState(false);

  useEffect(() => {
    fetch(`${url}/chat?uuid=${uuid}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setMessages([
            {
              content: `error: ${JSON.stringify(data.error)}`,
              user: 'assistant',
              date: Date.now(),
              error: true,
            },
          ]);
        } else {
          setMessages([
            ...data.map(message => {
              return {
                content: message.content,
                user: message.role,
                date: message.date,
                error: false,
              };
            }),
          ]);
        }
      })
      .catch((err) => {
        setMessages([
          {
            content: `error: ${err.message}`,
            user: 'assistant',
            date: Date.now(),
            error: true,
          },
        ]);
        setWaitingForReply(false);
      });
  }, [uuid, url]);

  const send = (message) => {
    setWaitingForReply(true);
    const new_message = { content: message, user: 'user', date: Date.now(), error: false };
    setMessages([...messages, new_message]);
    fetch(`${url}/chat`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uuid,
        message,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setMessages([
            ...messages,
            new_message,
            {
              content: `error: ${JSON.stringify(data.error)}`,
              user: 'assistant',
              date: Date.now(),
              error: true,
            },
          ]);
        } else {
          setMessages([
            ...messages,
            new_message,
            { content: data.content, user: data.role, date: data.date, error: false },
          ]);
          setWaitingForReply(false);
        }
      })
      .catch((err) => {
        setMessages([
          ...messages,
          {
            content: `error: ${err.message}`,
            user: 'assistant',
            date: Date.now(),
            error: true,
          },
        ]);
      });
  };

  return (
    <>
      <Container fluid="md" className="chat">
    { messages.map(message => (
      <Message
        content={ message.content }
        user={ message.user === 'user' }
        date={ new Date(message.date) }
        error={ message.error }
        key={ messages.indexOf(message) }
      />
    )) }
      </Container>
      <Container fluid="md">
        <InputBox disabled={ waitingForReply } send={ send }/>
      </Container>
    </>
  );
}
