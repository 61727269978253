import Chat from './Chat';
import Navigation from './Navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

const ChatPage = () => (
  <>
    <Navigation />
    <Chat />
  </>
);
const HomePage = () => {
  window.location.href = '/' + crypto.randomUUID();
  return <></>
};
const HealthPage = () => (
  <>
    <p>OK 5f9981d3</p>
  </>
);

export default function App() {
return (
    <Router>
      <Routes>
        <Route exact path="/health" element={ <HealthPage /> } />
        <Route path="/" element={ <HomePage /> } />
        <Route path="/:uuid" element={ <ChatPage /> } />
      </Routes>
    </Router>
  );
}
