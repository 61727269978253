import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import './InputBox.css';

export default function InputBox({ send, disabled }) {
  const submit = (event) => {
    event.preventDefault();
    const message = document.getElementById('form-message').value;
    if (!disabled && message.length > 0) {
      send(message);
      document.getElementById('form-message').value = '';
    }
  }
  return (
    <Row className="input-box">
      <Col className="">
        <Card className="border-0">
          <Card.Body>
            <form onSubmit={ submit }>
              <InputGroup className="mb-3" >
                <Form.Control
                  placeholder="Envoyez votre message."
                  id="form-message"
                />
                <Button variant="outline-secondary" type="submit" disabled={ disabled }>
                  Envoyer
                </Button>
              </InputGroup>
            </form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
